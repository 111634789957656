:root {
  --primary-color: #f5f5f5;
  --secundary-color: #021529;
  --backgroundColor: #fff;
  --backgroundPage: #efeef3;
  --gray: #d1d3d4;
  --foregroundColor: #111;
  --backgroundColor-navigation: rgba(249, 249, 249, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  padding-top: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
}

a,
button,
h1,
h2,
h3,
h4 {
  font-family: 'Poppins', sans-serif;
}

li {
  list-style: none;
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
  color: #000;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

button:focus,
button:active {
  outline: none;
}

input {
  outline: none;
  font-family: 'Poppins', sans-serif;
}

#root {
  width: 100%;
  position: absolute;
}

/* tamaños => 480 | 600 | 768 | 900 | 1200 */

@media screen and (max-width: 600px) {
  body {
    padding-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  body {
    padding-top: 45px;
  }
}

@media screen and (max-width: 900px) {
  body {
    padding-top: 50px;
  }
}

@media screen and (min-width: 900px) {
  body {
    padding-top: 45px;
  }
}

/* #efeef3 */
